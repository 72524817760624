<template>
  <v-data-table
    v-bind="dataTableAttrs"
    :headers="headersShown"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
    @update:items-per-page="handleItemsPerPageUpdated"
    :show-select="false"
  >
    <template v-slot:top>
      <rs-table-title-bar
        v-if="$refs.headSearch"
        title="Ödemeler"
        icon="mdi-cash-100"
        add-route=""
        @clear-filters="$refs.headSearch.reset"
        @reload="loadList"
        hide-edit
        :search.sync="search.query"
        v-bind="titleBarAttrs"
      ></rs-table-title-bar>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.prepend="{ headers }">
      <rs-table-head-search
        :headers="headers"
        :search.sync="search"
        ref="headSearch"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.append="{ headers }">
      <rs-table-foot-totals
        :headers="headers"
        :totals="footTotals"
        v-if="list.length > 0"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.edit="{ item }">
      <router-link
        class="btn btn-icon btn-sm btn-clean"
        :to="{
          name: 'admin.crm.customer-payments.edit',
          params: { id: item.id },
        }"
      >
        <i class="menu-icon mdi mdi-pencil"></i>
      </router-link>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.customer_name="{ item }">
      <router-link
        :to="{
          name: 'admin.crm.customers.edit',
          params: {
            id: item.customer_id,
          },
        }"
      >
        {{ item.customer_name }}
      </router-link>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.success_at="{ value }">
      <rs-table-cell-date show-time :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.amount="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.invoice_payment_amount="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.created_at="{ value }">
      <rs-table-cell-date show-time :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.updated_at="{ value }">
      <rs-table-cell-date show-time :value="value" />
    </template>
  </v-data-table>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { hasDataTable, hasExport } from "@/view/mixins";

export default {
  name: "AdminCustomerPaymentList",
  mixins: [hasDataTable, hasExport],
  props: {
    customerId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      options: {
        sortBy: ["success_at"],
        sortDesc: [true],
      },
      dataTableAttrs: {
        footerProps: {
          disableItemsPerPage: false,
        },
      },
      pageMeta: {
        title: "Müşteri Ödemeleri",
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Müşteri",
          value: "customer_name",
          searchable: "text",
          width: "250px",
        },
        {
          text: "Tarih",
          value: "success_at",
          searchable: "number",
          align: "end",
        },
        {
          text: "Tutar",
          value: "amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Ödenen Fatura Tutarı",
          value: "invoice_payment_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Oluşturma",
          value: "created_at",
          searchable: "date",
          width: "180px",
        },
        {
          text: "Düzenleme",
          value: "updated_at",
          searchable: "date",
          width: "180px",
        },
      ],
      titleBarAttrs: {
        exportUrl: () => "api-super-admin/customer-payments",
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.customer_id = this.customerId;

      return params;
    },
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];

      const params = this.getParams();

      this.$api
        .query("api-super-admin/customer-payments", { params })
        .then((response) => {
          this.loadListFromResponse(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  watch: {
    customerId() {
      this.loadList();
    },
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.loadList();
      }, 250),
      deep: true,
    },
  },
};
</script>
